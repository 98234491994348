import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import TravelCarousel from './components/TravelCarousel';
import Reason from './components/Reason';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CustomerTerms from './Pages/CustomerTerms.jsx';
import TourGuideTerms from './Pages/Tourguide.jsx';
import TourBuddyTerms from './Pages/TourBuddies.jsx';
import PrivacyPolicy from './Pages/PrivacyPolicy.jsx';
import Blog from './Pages/Blog.jsx';
import BlogOne from './BlogPages/BlogOne.jsx';
import BlogTwo from './BlogPages/BlogTwo.jsx';
import BlogThree from './BlogPages/BlogThree.jsx';
import AdminPanel from './Pages/Admin.jsx';
import Contact from './components/Contact';
import Modal from './components/Modal'; // Import the Modal component


function HomePage() {
  // const [showModal, setShowModal] = useState(true); // Always show modal on page load
  const [showModal, setShowModal] = useState(false); // Initially hidden

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true); // Show modal after homepage loads
    }, 1000); // Delay of 2 seconds

    return () => clearTimeout(timer); // Cleanup function
  }, []);

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <>
      {/* Show modal only on the home page */}
      {showModal && <Modal onClose={handleCloseModal} />}
      <Hero />
      <TravelCarousel />
      <Reason />
      <Contact />
    </>
  );
}

const Fast2SMSVerifyRedirect = () => {
  useEffect(() => {
    // Trigger download
    const link = document.createElement("a");
    link.href = "/fast2sms_verify.txt"; // Correct path from public folder
    link.download = "fast2sms_verify.txt"; // Ensures it prompts download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return <Navigate to="/" />;
};

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {/* Home route with modal */}
          <Route path="/" element={<HomePage />} />
          
          <Route path="/customer-terms" element={<CustomerTerms />} />
          <Route path="/tour-guide-terms" element={<TourGuideTerms />} />
          <Route path="/tour-buddy-terms" element={<TourBuddyTerms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/varanasi-travel-explore-temples-and-ghats-like-a-local-expert" element={<BlogOne />} />
          <Route path="/plan-your-perfect-trip-best-tour-packages-for-mahakumbh" element={<BlogTwo />} />
          <Route path="/celebrate-holi-in-braj-an-unforgettable-festival-experience-with-voyease" element={<BlogThree />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/fast2sms_verify.txt" element={<Fast2SMSVerifyRedirect />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
