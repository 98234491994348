import React, {useEffect} from 'react';
import kumbh1 from '../assets/img/blog/kumbh1.png';
import kumbh2 from '../assets/img/blog/kumbh2.png';

const BlogOne = () => {

  useEffect(() => {
      document.title = 'Plan Your Perfect Trip: Best Tour Packages for Mahakumbh';
  
      // Update meta description
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = 'Voyease makes it easier than ever to plan your trip to Prayagraj Mahakumbh. Regardless of your taste for a more luxurious or less expensive option.';
    }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="relative h-40">
        <div className="absolute inset-0 opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-black px-5 w-3/4 m-auto">
          <p className="sm:text-2xl md:text-3xl lg:text-4xl font-bold">Plan Your Perfect Trip: Best Tour Packages for Mahakumbh</p>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="px-4 md:px-8 max-w-screen-lg mx-auto">
        {/* <h2 className="text-3xl font-bold text-center mb-12">Gallery</h2> */}
        <div className="">
          {/* Image 1 */}
          {/* <img src={blogOne} alt="Burj Khalifa 1" className="w-full h-64 object-cover rounded-lg shadow-md" /> */}
          {/* Image 2 */}
          <img src={kumbh1} alt="Burj Khalifa 2" className="w-full h-auto object-cover rounded-lg shadow-md" />
          {/* Image 3 */}
          {/* <img src={blogFive} alt="Burj Khalifa 3" className="w-full h-64 object-cover rounded-lg shadow-md" /> */}
        </div>
      </div>

      {/* Content Section */}
      <div className="py-16 px-4 md:px-8 max-w-screen-lg mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="lg:w-3/3">
            <p className="text-black mb-4">
            Whether you’re traveling for spiritual reasons or simply to witness one of the world’s largest gatherings, the journey itself is an unforgettable adventure. At Voyease, we understand the significance of this holy occasion and have curated exclusive tour packages to ensure you have a seamless, enriching experience.
            </p>
            <h2 className="text-2xl font-bold mb-6">Why Choose Voyease for Your Trip to Mahakumbh ?</h2>
            <p className="text-gray-700 mb-4">
            Your <a href="https://www.voyease.in/" className="text-blue-500"><u>trip to Prayagraj Mahakumbh</u></a> will be hassle-free and unforgettable thanks to Voyease's ideal fusion of comfort, convenience, and culture. The following justifies picking one of our carefully selected tour packages:
            </p>
            <h2 className="text-xl font-bold mb-4">Important Features of Voyease's Mahakumbh Packages:</h2>
            <ul className="list-disc pl-10">
                <li>All-Inclusive Experience: You may concentrate on the spiritual aspect of the journey because everything is covered, including lodging and transportation.</li>
                <li>Travel with educated guides who can tell you about the background and importance of each place you visit.</li>
                <li>Packages that can be customized: Whether you're searching for a more opulent experience or something more affordable, you may make your vacation fit your interests.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-6">Tour Inclusions:</h2>
            <h2 className="text-lg font-bold mb-2">Cab Facility:</h2>
            <ul className="list-disc pl-10">
                <li>Experienced drivers and cozy, air-conditioned taxis guarantee a comfortable and enjoyable ride.</li>
                <li>Services for pickup and drop-off from Varanasi to Lucknow, including all intermediate locations.</li>
                <li>Adaptable travel plans to your convenience and comfort level.</li>
            </ul>

            <h2 className="text-lg font-bold mt-2 mb-2">Accommodation:</h2>
            <ul className="list-disc pl-10">
                <li>Budget-friendly lodging options include cozy, reasonably priced hotels and guesthouses with tidy, secure rooms.</li>
            </ul>

            <h2 className="text-lg font-bold mt-2 mb-2">Local Touring:</h2>
            <ul className="list-disc pl-10">
                <li>Discover must-see sites including the famous Kashi Vishwanath Temple in Varanasi, the Triveni Sangam in Prayagraj, and the Ram Janmabhoomi in Ayodhya.</li>
                <li>Take a calm boat trip down the Ganges and witness the Ganga Aarti in Varanasi.</li>
                <li>For spiritual moments of contemplation, visit the Bade Hanuman Mandir in Prayagraj and Hanuman Garhi in Ayodhya.</li>
            </ul>

            <img className="mt-8 mb-6 w-full" src={kumbh2} alt="kumbh-boat" />



            
            <h2 className="text-2xl font-bold mb-6">Reasons for Selecting Voyease's Mahakumbh Packages?</h2>
            <ul className="list-disc pl-10">
                <li><strong>Budget-friendly choice:</strong> We provide a variety of packages to suit a range of spending levels, enabling everyone to take part in the Mahakumbh. Our <a href="https://www.voyease.in/" className="text-blue-500"><u>budget-friendly trip</u></a> options don't sacrifice comfort or quality.</li>
                <li><strong>Comfort & Safety:</strong> We guarantee a safe, pleasant, and joyful journey with our well-maintained cars, carefully chosen lodgings, and knowledgeable guides.</li>
                <li><strong>Flexibility:</strong> Voyease lets you personalize the tour to fit your interests and timetable, whether you're planning a quick getaway or a longer excursion.</li>
                <li><strong>Proficient Arrangement:</strong> For first-time visitors, the Mahakumbh may be daunting, but with Voyease, you can be sure that your vacation will be organized by professionals who are aware of the importance of this religious assembly.</li>
            </ul>

            <h2 className="text-xl font-bold mt-6 mb-6">Conclusion :</h2>
            <p className="text-gray-700 mb-4">
            Voyease makes it easier than ever to plan your trip to Prayagraj Mahakumbh. Regardless of your taste for a more luxurious or less expensive option, Voyease ensures a fun, hassle-free vacation to one of the most spectacular spiritual events in the world. Just pack your luggage and let us take care of the rest; your dream trip to Mahakumbh is waiting for you. We can be reached at “https://www.voyease.in/” or by downloading our app, "VoyEase: Trips Now Easy."
            </p>

          </div>

          
        </div>
      </div>
    </div>
  );
};

export default BlogOne;
