import React, {useEffect} from 'react';
// import blogOne from '../assets/img/blog/pexels-bhaveshjain-3988075.jpg';
// import blogTwo from '../assets/img/blog/pexels-rachel-claire-8112558.jpg';
// import blogThree from '../assets/img/blog/pexels-ravijha95-12112985.jpg';
// import blogFive from '../assets/img/blog/pexels-rachel-claire-8112531.jpg';
import holi1 from '../assets/img/blog/holiImg.jpg';
// import blogFour from '../assets/img/blog/pexels-rachel-claire-8112555.jpg';
// import blogSix from '../assets/img/blog/pexels-subhajit-paul-213168853-12375961.jpg';

const BlogThree = () => {

    useEffect(() => {
        document.title = "Experience Holi in Braj: A Traveler's Guide to the Festivities";
    
        // Update meta description
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
          metaDescription = document.createElement('meta');
          metaDescription.name = 'description';
          document.head.appendChild(metaDescription);
        }
        metaDescription.content = 'Tour guide services in Braj (Mathura, Vrindavan, and Barsana) at unmatchable budget rates.  Celebrating Holi in Braj with Voyease means a fabulously enjoyable time.';
      }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="relative h-40">
        <div className="absolute inset-0 opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-black px-5 w-3/4 m-auto">
          <p className="sm:text-2xl md:text-3xl lg:text-4xl font-bold">Celebrate Holi in Braj: An Unforgettable Festival Experience with Voyease</p>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="px-4 md:px-8 max-w-screen-lg mx-auto">
        {/* <h2 className="text-3xl font-bold text-center mb-12">Gallery</h2> */}
        <div className="">
          {/* Image 1 */}
          {/* <img src={blogOne} alt="Burj Khalifa 1" className="w-full h-64 object-cover rounded-lg shadow-md" /> */}
          {/* Image 2 */}
          <img src={holi1} alt="Burj Khalifa 2" className="w-full h-auto object-cover rounded-lg shadow-md" />
          {/* Image 3 */}
          {/* <img src={blogFive} alt="Burj Khalifa 3" className="w-full h-64 object-cover rounded-lg shadow-md" /> */}
        </div>
      </div>

      {/* Content Section */}
      <div className="py-16 px-4 md:px-8 max-w-screen-lg mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="lg:w-3/3">
            <p className="text-black mb-4">
            So you've made plans to celebrate Holi in Braj? Not to worry—Voyease is here to provide its services during Holi for you. Celebrate the festival of colors in Braj with a quick budget tour. Holi is the festival of colors and is celebrated at an unparalleled peak in the Braj region of India. This zone, which consists of towns like Mathura, Vrindavan, Barsana, and Nandgaon, carries a heavy association with the legends of Lord Krishna and Radha. Celebrating Holi here gives a unique perspective on spirituality, tradition, and merriment. Witnessing and participating in these ancient celebrations is a memory lane with Voyease.
            </p>
            <h2 className="text-2xl font-bold mb-6">Why Choose Voyease for Your Braj Holi Experience ?</h2>
            <ul className="list-disc pl-10">
                <li><strong>Tour Guides for Professionals:</strong> Our expert guides are well-versed in the centuries-old traditional exhibition of the Braj area. They will also offer clear historical derivations so that guests can learn the cultural and religious frameworks of this or that Holi festival.</li>
                <li><strong>Budget-Friendly Accommodation Options:</strong> The finest hotels wouldn't necessarily cost you a fortune. Voyease provides affordable hotel choices close to the main Holi festivities.</li>
                <li><strong>Quick Book:</strong> From start to finish, the process is easy and <a href="https://www.voyease.in/" className="text-blue-500"><u>quick booking</u></a>. With this non-easy, overwhelming process, you can get seats faster and put more focus on the vibrant upcoming celebrations.</li>
                <li><strong>Fully Guided Tour:</strong> Guide the guests to witness various events and rituals related to Braj Holi. From the very playful 'Lathmar Holi' of Barsana to the very solemn 'Phoolon ki Holi' at Vrindavan, everything is designed to give one a sense of immersive experience.</li>
                <li><strong>Personal Vehicle Transportation:</strong> It is comfortable with our cars between the various towns and celebration sites and offers flexibility in your schedule and a relaxed traveling experience.</li>
            </ul>


            

            <h2 className="text-xl font-bold mt-4 mb-6">Highlights of the Braj Holi Tour with Voyease</h2>
            <ul className="list-decimal pl-10">
                <li><strong>Lathmar Holi in Barsana:</strong> Which is quite an event. What makes it unique is that a woman would chase a man with a stick as a play act reflecting the antics of Radha and Krishna.</li>
                <li><strong>Phoolon ki Holi in Vrindavan:</strong> This is the most exciting time when some of the most famous devotees shower them with flower petals, making it a spectacular show of colors and fragrances.</li>
                <li><strong>Huranga at Dauji Temple:</strong> This is an unforgettable event accompanied by music and dance and depicts all colors of joy in the festival.</li>
            </ul>

            <h2 className="text-lg font-bold mt-2 mb-2">Booking Your Braj Holi Tour with Voyease</h2>
            <p>Aside from this, we provide <a href="https://www.voyease.in/" className="text-blue-500"><u>tour guide services in Braj</u></a> (Mathura, Vrindavan, and Barsana) at unmatchable budget rates. Celebrating Holi in Braj with Voyease means a hassle-free, very enriching, and fabulously enjoyable time. Our packages talk in depth about the cultural and spiritual essence of the festival, making your trip memorable and worthwhile. Call us now to fix your tour and stay a part of this one-in-a-million celebration!</p>

            
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default BlogThree;
