import React from 'react';
import HeroImage from "../assets/img/HeroImage.jpg"

const Hero = () => {

  return (
    <div id="home" className='bg-white mt-4 md:mt-10'>
      <div className='absolute inset-0 bg-white bg-opacity-10'></div>

      <div className='relative z-10 flex flex-col md:flex-row gap-8 justify-center items-center text-white text-center h-full'>

        {/* text Section */}
        <div className="text-left mt-8 md:mt-0 md:w-[50%] lg:w-[40%]">
          <p className="text-transparent bg-clip-text bg-gradient-to-b from-blue-900 to-black font-sans whitespace-nowrap text-3xl md:text-4xl lg:text-5xl font-bold md:py-2"> Unlock Hidden Treasures: </p>
          <p className="text-transparent bg-clip-text bg-gradient-to-b from-blue-900 to-black whitespace-nowrap text-3xl md:text-4xl lg:text-5xl font-bold md:py-2"> Explore Like a Local. </p>
          <p className="text-justify font-sans font-semibold text-slate-950 tracking-widest sm:text-lg md:text-xl leading-relaxed text-shadow-md mt-4 sm:mt-8 md:mt-14">VoyEase-Welcome to Trips Now Easy! Discover hidden gems, shop for unique finds, and learn the best-kept secrets with our professional tour guide. Book Hotels, Cabs and Much More Turn an unforgettable adventure into any journey. Are we ready to go?</p>

          {/* Register Buttons */}

          <div className="bg-white rounded-lg mt-8 flex flex-col gap-4 justify-center">
            <div>
              <a href="https://mobile.voyease.in/" target="_blank" rel="noopener noreferrer">
                <button className="font-bold rounded-tr-full rounded-br-full rounded-bl-full rounded-tl-none px-6 py-2 bg-[#fc3c0c] transition duration-600 ease-in-out hover:rounded-tl-full hover:shadow-lg hover:shadow-[#fc8c7c] w-full sm:w-96">
                  Register yourself as TOUR GUIDE
                </button>
              </a>
            </div>
            <div>
              <a href="https://app.voyease.in/" target="_blank" rel="noopener noreferrer">
                <button className="font-bold rounded-tr-full rounded-br-none rounded-bl-full rounded-tl-full px-6 py-2 bg-[#fc3c0c] transition duration-600 ease-in-out hover:rounded-br-full  hover:shadow-lg hover:shadow-[#fc8c7c] w-full sm:w-96">
                  Register as a TOURIST
                </button>
              </a>
            </div>
          </div>

        </div>

        {/* Image section */}

        <div className="mt-8 md:mt-0">
          <img src={HeroImage} alt="Hero" className="h-full object-contain md:h-80 lg:h-[500px] w-full" />
        </div>
      </div>


    </div>
  );
};

export default Hero;