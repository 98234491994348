import React from 'react';
import { useState, useEffect } from "react";
import place1 from '../assets/newimg/Component 9.png';
import place2 from '../assets/newimg/Component 9 (1).png';
import place3 from '../assets/newimg/Component 9 (2).png';
import img1 from "../assets/newimg/Ellipse 18 (1).png";
import img2 from "../assets/newimg/Ellipse 18 (2).png";
import curveTop from "../assets/newimg/Vector 4.png";
import curveBottom from "../assets/newimg/Vector 5.png";

const TourPage = () => {
  const images = [place1, place2, place3];

  const [textIndex, setTextIndex] = useState(0);
  const texts = ["Tour Guides", "Tour Buddies"];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 5000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full max-w-screen-xl mx-auto mt-12 p-6 space-y-12 md:space-y-8">
      {/* Image Carousel */}
      <div className="carousel-container h-auto md:h-[20cm] overflow-hidden">
        <div className="mb-12">
          <h2 className="text-4xl font-bold">
            <span className="text-black">Explore with </span>
            <span className="animated-text text-transparent bg-clip-text bg-gradient-to-r from-[#285A84] to-[#F4380F] text-5xl">
            {texts[textIndex]}
            </span>
          </h2>
        </div>
        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-4">
          {images.map((image, index) => (
            <div key={index} className="w-full md:w-1/3 px-4 sm:px-6 md:px-8 lg:px-10 py-2">
              <img
                src={image}
                alt={`Place ${index + 1}`}
                className="w-full h-auto md:h-[13cm] lg:h-[12cm] object-cover rounded-2xl shadow-xl"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Tour Guide Section */}
      <div className="relative flex flex-col md:flex-row items-center px-6 md:px-12">
        <img src={curveTop} alt="Curve Top" className="absolute -top-10 -left-4 w-16 h-16 sm:w-20 sm:h-20 md:w-28 md:h-28" />
        <div className="md:w-1/2 space-y-4">
          <h2 className="text-white bg-[#F4380F] py-2 px-14 inline-block rounded-full text-xl font-bold">
            Tour Guide
          </h2>
          <p className="text-gray-700">
          You can build your personalized itinerary for travel, with our proficient and experienced tour guide. They will also show you around the new place like a local. They are your personal trainers when you travel and are also quite affordable.
          </p>
          {/* <button className="border border-gray-700 px-14 py-2 rounded-full hover:bg-gray-200">
            Book Now
          </button> */}
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img src={img1} alt="Tour Guide" className="w-72 h-72 object-cover rounded-full" />
        </div>
      </div>

      {/* Tour Buddy Section */}
      <div className="relative flex flex-col md:flex-row-reverse items-center px-6 md:px-12">
        <div className="md:w-1/2 space-y-4 text-right">
          <h2 className="text-white bg-[#285A84] py-2 px-14 inline-block rounded-full text-xl font-bold">
            Tour Buddy
          </h2>
          <p className="text-gray-700">
          Are you planning a vacation but afraid of the costs? Stop worrying about high travel packages! Leverage your friends and acquaintances locally as a very important resource.  They can serve as personal and inexpensive tour guides because they know the city and its surrounding areas so well. They would assist you in everything from bargaining for good prices when you shop to reserving cheap hotels and navigating your way through local taxis.  How would it feel to best use insider knowledge in budget your trip?
          </p>
          {/* <button className="border border-gray-700 px-14 py-2 rounded-full hover:bg-gray-200">
            Book Now
          </button> */}
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img src={img2} alt="Tour Buddy" className="w-72 h-72 object-cover rounded-full" />
        </div>
        <img src={curveBottom} alt="Curve Bottom" className="absolute -bottom-10 -right-4 w-16 h-16 sm:w-20 sm:h-20 md:w-28 md:h-28" />
      </div>
    </div>
  );
};

export default TourPage;
